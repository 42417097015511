//トップページ　横スクロール
import gsap from 'gsap';
import {
  ScrollTrigger
} from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import anime from 'animejs/lib/anime.es.js';

import 'jquery.cookie';

export function recruitTopFunc() {
  let recruitTop = $('#recruitTop').length;

  if (recruitTop) {

    const wait = ms => new Promise(resolve => setTimeout(() => resolve(), ms));

    var animateMainviewRecruitFlag05 = false;
    var animateMainviewRecruitFlag06 = false;

    var animateMainviewScrollRecruitFlag04 = false;
    var animateMainviewScrollRecruitFlag05 = false;
    var animateMainviewScrollRecruitFlag06 = false;

    if (!animateMainviewScrollRecruitFlag04) {
      animateMainviewScrollRecruitFlag04 = true;

      if (!$('#seen05').is(':animated')) {
        $("#commonSeen").addClass('isShow').delay(1000).queue(function () {
          $('#seen05').animate({
            opacity: 1,
          }, 1000, function () {
            $('#seen05 .imgFade').animate({
              opacity: 1,
            }, 600, function () {
              $("#seen05 .titAnime").css({
                transform: "scale(1)"
              }).delay(1000).queue(function () {
                $("#mainviewScroll").addClass('isShow isGradation');
                animateMainviewRecruitFlag05 = true;
              });
            });
          });
        });
      }
    }

    function mainviewAnimeFunc() {
      if (!animateMainviewScrollRecruitFlag05 && animateMainviewRecruitFlag05) {
        animateMainviewScrollRecruitFlag05 = true;

        if (!$('#seen06').is(':animated') && animateMainviewRecruitFlag05) {
          $('#seen05').animate({
            opacity: 0,
          }, 500, function () {
            $('#seen06').animate({
              opacity: 1,
            }, 1000, function () {
              $('#seen06 .imgFade').animate({
                opacity: 1,
              }, 600, function () {
                $("#seen06 .titAnime").css({
                  transform: "scale(1)"
                }).delay(1400).queue(function () {
                  animateMainviewRecruitFlag06 = true;
                });
              });
            });
          });
        }
      }

      if (!animateMainviewScrollRecruitFlag06 && animateMainviewRecruitFlag06) {
        animateMainviewScrollRecruitFlag06 = true;

        wait(0)
          .then(() => {
            $('#mainviewSeen').addClass('hidden');
            return wait(800)
          })
          .then(() => {
            $('body').removeClass('fixedBody').css({
              'top': 0
            });
            return wait(500)
          })
          .then(() => {
            $('.mainviewTit').addClass('isShow');

            anime({
              targets: '.mainviewTit path',
              strokeDashoffset: [anime.setDashoffset, 0],
              stroke: '#fff',
              easing: 'easeInOutSine',
              duration: 2000,
            });
            return wait(800)
          })
          .then(() => {
            $('.recruitAboutTitArea').addClass('isShow');
            return wait(800)
          })
          .then(() => {
            $('.recruitAboutTit').addClass('isShow');
            return wait(600)
          })

        $.cookie("cookieRecruit", "mainviewRecruitAnime");
      }
    }

    if ($.cookie("cookieRecruit") == "mainviewRecruitAnime") {
      $('#mainviewSeen').addClass('none');
      $('body').removeClass('fixedBody').css({
        'top': 0
      });

      wait(0)
        .then(() => {
          $('.mainviewTit').addClass('isShow');

          anime({
            targets: '.mainviewTit path',
            strokeDashoffset: [anime.setDashoffset, 0],
            stroke: '#fff',
            easing: 'easeInOutSine',
            duration: 2000,
          });
          return wait(800)
        })
        .then(() => {
          $('.recruitAboutTitArea').addClass('isShow');
          return wait(800)
        })
        .then(() => {
          $('.recruitAboutTit').addClass('isShow');
          return wait(600)
        })
    }
    if ($.cookie("cookieRecruit") == undefined) {
      $(window).on('wheel', function () {
        mainviewAnimeFunc();
      });
      $(window).on('touchmove', function () {
        mainviewAnimeFunc();
      });
      $(window).on('click', function () {
        mainviewAnimeFunc();
      });
    }

    $.cookie('cookieRecruit', 'mainviewRecruitAnime', {
      path: '/',
      domain: '.ripple.co.jp'
    });

    document.querySelectorAll(".fadeInRecruit").forEach((el) => {
      gsap.fromTo(
        el, {
          y: 30,
          opacity: 0,
        }, {
          y: 0,
          opacity: 1,
          duration: .8,
          // スクロールトリガーの登録
          scrollTrigger: {
            trigger: el,
            start: "top 90%",
            ease: "expo",
            invalidateOnRefresh: true,
            //markers: true,
          },
        }
      );
    });
  }
}