//トップページ　横スクロール
import gsap from 'gsap';
import {
  ScrollTrigger
} from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import anime from 'animejs/lib/anime.es.js';

export function worksTopFunc() {
  let worksTop = $('#worksTop').length;

  if (worksTop) {

    $('.mainviewTit').addClass('isShow');
    
    anime({
      targets: '.mainviewTit path',
      strokeDashoffset: [anime.setDashoffset, 0],
      stroke: '#fff',
      easing: 'easeInOutSine',
      duration: 2000,
    });

    //on air
    const listWrapperEl = document.querySelector('.onAirProgramListWrap');
    const listEl = document.querySelector('.onAirProgramList');

    ScrollTrigger.matchMedia({
      "(max-width: 960px)": function() {
        gsap.to(listEl, {
          x: () => -(listEl.clientWidth - listWrapperEl.clientWidth),
          ease: 'none',
          scrollTrigger: {
            trigger: ".onAirArea",
            start: "top top",
            end: () => `+=${listEl.clientWidth - listWrapperEl.clientWidth}`,
            scrub: true,
            pin: true,
            invalidateOnRefresh: true,
          },
        });
      }
    });

    document.querySelectorAll(".fadeInworks").forEach((el) => {
      gsap.fromTo(
        el, {
          y: 30,
          opacity: 0,
        }, {
          y: 0,
          opacity: 1,
          duration: .8,
          // スクロールトリガーの登録
          scrollTrigger: {
            trigger: el,
            start: "top 90%",
            ease: "expo",
            invalidateOnRefresh: true,
            //markers: true,
          },
        }
      );
    });

  }
}