//トップページ　横スクロール
import anime from 'animejs/lib/anime.es.js';

export function aboutUsTopFunc() {
  let aboutUsTop = $('#aboutUsTop').length;

  if (aboutUsTop) {

    $('.mainviewTit').addClass('isShow');
    
    anime({
      targets: '.mainviewTit path',
      strokeDashoffset: [anime.setDashoffset, 0],
      stroke: '#fff',
      easing: 'easeInOutSine',
      duration: 2000,
    });

    let scrollFadeInFunc = function() {
      $('.fadeInAbout').each(function () {
        var position = $(this).offset().top;
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        
        if (scroll > position - windowHeight + 100) {
          $(this).addClass('active');
        }
      });
    }

    scrollFadeInFunc();
    $(window).on('scroll', function () {
      scrollFadeInFunc();
    });

  }
}