export function footerFunc() {
  let bottomPage = $('#bottom').length;

  if (bottomPage) {
    let lastContant = document.querySelector('#lastContant');
    let footer = document.querySelector('#footer');
    let breadcrumb = document.querySelector('#breadcrumb');
    const optionsFooter = {
      rootMargin: '-40% 0px -40% 0px'
    };
    const callbackFooter = (function (changes) {
      for (let change of changes) {
        let target = change.target;
        if (change.isIntersecting) {
          lastContant.classList.add('scrollDown');
          footer.classList.add('scrollDown');
          breadcrumb.classList.add('scrollDown');
        } else{
          lastContant.classList.remove('scrollDown');
          footer.classList.remove('scrollDown');
          breadcrumb.classList.remove('scrollDown');
        }
      }
    });

    const observerFooter = new IntersectionObserver(callbackFooter, optionsFooter);

    const targetFooter = document.querySelector('#footer');
    observerFooter.observe(targetFooter);
  }
}