export function headerFunc() {
  let headerPage = $('#header').length;
  let bottomPage = $('#bottom').length;
  const header = $('#header');
  const navBtn = $('#navBtn');
  const navMenu = $('#navMenu');
  const headerLogo = $('#headerLogo');
  let menuOpenFlg = 0;
  let scrollPosition;

  if (headerPage) {
    navBtn.on('click', function () {
      navBtn.toggleClass('isOpen');
      if (navBtn.hasClass('isOpen')) {
        scrollPosition = $(window).scrollTop();
        menuOpenFlg = 1;
        navMenu.addClass('isShow');
        headerLogo.addClass('isShow');
        //$('body').addClass('fixed');
        $('body').addClass('noScroll').css({
          'overflow-y': 'hidden',
          //'top': -scrollPosition
        });
      } else {
        menuOpenFlg = 0;
        navMenu.removeClass('isShow');
        headerLogo.removeClass('isShow');
        //$('body').removeClass('noScroll');
        $('body').removeClass('noScroll').css({
          'overflow-y': 'scroll',
          'top': 0
        });
        window.scrollTo(0, scrollPosition);
      }
    });

    const navAccordionBtn = $('#navMenu .accordionBtn');
    navAccordionBtn.on('click', function () {
      if ($(this).hasClass('isOpen')) {
        $(this).next('dd').slideUp(500);
        $(this).removeClass('isOpen');
      } else {
        navAccordionBtn.next('dd').slideUp(500);
        navAccordionBtn.removeClass('isOpen');
        $(this).next('dd').slideDown(500);
        $(this).addClass('isOpen');
      }
    }); 
  }

  if (bottomPage) {
    let setTimer = false;
    if (setTimer !== false) {
      clearTimeout(setTimer);
    }
    setTimer = setTimeout(() => {
      navBtn.addClass('isShow');
      header.addClass('isShow');
      headerLogo.addClass('isBottomShow');
    }, 800);
  }
}

export function headerScrFunc() {
}